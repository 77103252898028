<template>
  <nav class="nav" v-if="!hiddenGlobalNav">
    <div class="left">
      <img class="icon" src="../assets/backtrack.png" alt="icon" @click="back">
      <div class="flex" v-for="(item, index) in $store.state.routerList" :key="index">
        <router-link :to="{name: item.routerName, query: item.query}" class="link"><span v-html="item.name"></span>
        </router-link>
        <span class="slash">/</span>
      </div>
      <div class="leaf-name" v-html="name"></div>
    </div>
    <slot name="right"></slot>
  </nav>
</template>

<script>
export default {
  name: 'RouterNav',
  props: ['name'],
  data(){
    return {
      hiddenGlobalNav: false
    }
  },
  created() {
    this.hiddenGlobalNav = sessionStorage.getItem('hiddenGlobalNav')
  },
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  @include font(15px, #309AF2， 21px);

  .left {
    display: flex;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .flex {
    display: flex;
    align-items: center;
  }
  .slash{
    color: #999999;
    font-size: 15px;
    line-height: 21px;
    margin: 0 8px;
  }

  .leaf-name {
    color: #333333;
  }

  .link {
    color: #999999;
    cursor: pointer;

    &:hover {
      color: #333333;
    }
  }

  .icon2 {
    width: 4px;
    height: 7px;
    margin: 0 8px;
  }
}
</style>
